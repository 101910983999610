<template>
    <div class="bg-gray-100 dark:bg-gray-900 pb-24 h-lvh">
        <nav class="flex flex-wrap items-center justify-between fixed top-0 z-40 w-full backdrop-blur shadow-sm dark:bg-black p-2 bg-white">
            <PLogo />
            <div>
                <UTooltip text="Alternar modo escuro/claro">
                    <UButton :icon="!isDark ? 'i-heroicons-moon-20-solid' : 'i-heroicons-sun-20-solid'" variant="ghost"
                        aria-label="Theme" @click="isDark = !isDark" />
                </UTooltip>
                <UTooltip text="Novidades" class="hidden ">
                    <UButton id="beamerBt" icon="i-heroicons-newspaper" variant="ghost" />
                </UTooltip>
            </div>
        </nav>
        <div class="pt-6 flex items-center justify-center h-screen">
            <slot />
        </div>
    </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
const colorMode = useColorMode();

const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    }
})

</script>